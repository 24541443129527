import React from "react"
import useTranslations from "../../utils/useTranslations"
import FeaturesList from "../../components/featuresList"
import ComparisonPanels from "../../components/comparisonPanels"
import btWebImgWebp from "../../images/homepage/screen-web.webp"
import btWebImg from "../../images/homepage/screen-web.png"
import btWebImgWebp2x from "../../images/homepage/screen-web@2x.webp"
import btWebImg2x from "../../images/homepage/screen-web@2x.png"
import classicImgWebp from "../../images/homepage/screen-classic-laptop.webp"
import classicImg from "../../images/homepage/screen-classic-laptop.png"
import classicImgWebp2x from "../../images/homepage/screen-classic-laptop@2x.webp"
import classicImg2x from "../../images/homepage/screen-classic-laptop@2x.png"
import classicMacImgWebp from "../../images/homepage/screen-classic-mac-laptop.webp"
import classicMacImg from "../../images/homepage/screen-classic-mac-laptop.png"
import classicMacImgWebp2x from "../../images/homepage/screen-classic-mac-laptop@2x.webp"
import classicMacImg2x from "../../images/homepage/screen-classic-mac-laptop@2x.png"
import androidImgWebp from "../../images/homepage/screen-android-mobile.webp"
import androidImg from "../../images/homepage/screen-android-mobile.png"
import androidImgWebp2x from "../../images/homepage/screen-android-mobile@2x.webp"
import androidImg2x from "../../images/homepage/screen-android-mobile@2x.png"
import arrowWhite from "../../images/icons/arrow-white.svg"
import warning from "../../images/icons/warning.svg"
import flagBrazil from "../../images/homepage/flag-of-brazil.svg"
import flagKorea from "../../images/homepage/flag-of-korea.svg"
import flagSpain from "../../images/homepage/flag-of-spain.svg"
import flagRussia from "../../images/homepage/flag-of-russia.svg"
import LocalizedLink from "../../components/localizedLink"
import Button from "../../components/button"
import { CompatibilityContext } from "../../components/compatibilityContext";
import Logo from "../../components/logo"
import backgrounds from "../../components/backgrounds"
import ProductCatalog from "../../components/productCatalog";
import gwIcon from "../../images/promotions/goldenwallet/gw-icon.svg"
import { ANDROID_LINK_FREE, getAndroidLink } from "../../utils/getAndroidLink";

class Index extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      isMac: false,
      isCatalina:false,
      isAndroid: false,
      displayMac:"d-none",
      androidLinkFree: ANDROID_LINK_FREE
    };
    this.handleWebClick = this.handleWebClick.bind(this)
    this.handleClassicClick = this.handleClassicClick.bind(this)
  }


  componentDidMount(){

    this.setState({
      isMac:window.gatsbyIsMac,
      isCatalina:window.gatsbyIsCatalina,
      displayMac:window.gatsbyIsMac ? "" : "d-none",
      isAndroid: window.gatsbyIsAndroid,
      androidLinkFree: getAndroidLink('free')
    })
  }

  handleClassicClick(e){
    this.context(e, 'downloading', "Classic", ["Win", "Osx"])

  }

  handleWebClick(e){
    this.context(e, 'downloading', "Web", ["Win", "Osx"])
  }

  render(){
    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)

    let classicLaptop = (this.state.isMac) ? classicMacImg : classicImg;
    let classicLaptopWebp = (this.state.isMac) ? classicMacImgWebp : classicImgWebp;
    let classicLaptop2x = (this.state.isMac) ? classicMacImg2x : classicImg2x;
    let classicLaptopWebp2x = (this.state.isMac) ? classicMacImgWebp2x : classicImgWebp2x;
    
    const locale = this.props.pageContext.locale
    const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`
    const appStoreButton = `/images/app-store-badges/app-store-badge-${locale}.svg`

    const englishHomepage = this.props.path

    return (
      <>
        {
          this.state.isAndroid &&  
          <div className="top-section-container p-0 pt-md-3 d-md-none mb-5">

            <div className="container text-dark py-2">

              <div className="row text-center mt-4 mt-sm-0">
                <div className="col-md-6 order-md-1 px-md-0 d-flex flex-column justify-content-center align-items-center">
                  <Logo color="black" tag="p" productName="Android" className="mb-4" fsCoefficient={18}/>

                  <picture>
                    <source srcSet={`${androidImgWebp} 1x, ${androidImgWebp2x} 2x`} type="image/webp"></source>
                    <img srcSet={`${androidImg} 1x, ${androidImg2x} 2x`} src={androidImg} className="img-fluid d-block d-md-none mb-4 android-hero"></img>
                  </picture>

                  <a href={this.state.androidLinkFree} id="homepage-mobile-android-hero" className="index-mobile-google-play" target="_blank" rel="noopener noreferrer">
                    <img className="google-play-button img-fluid" src={googlePlayButton} alt="BitTorrent for Android Google Play Store badge"></img>
                  </a>
                </div>

              </div>
            </div>
          </div>
        }
        <div className="top-section-container p-0 pb-md-5 pt-md-3 background-noise-dark" style={backgrounds.darkCorners}>

          <div className="container py-2">

            <div className="row text-center mt-4 mt-sm-5">
              <div className="col-md-6 mt-md-4 order-md-1">
                <Logo color="black" tag="h2" productName="Web" className="mb-4"/>
                {/*<img src={btWebImg} alt="Download BitTorrent Web" className="img-fluid d-block d-md-none mb-4"></img>*/}

                <picture>
                  <source srcSet={`${btWebImgWebp} 1x, ${btWebImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${btWebImg} 1x, ${btWebImg2x} 2x`} src={btWebImg} className="img-fluid w-100 d-block d-md-none" alt="Download BitTorrent Web"></img>
                </picture>
                <div style={{fontSize: '10px'}} className="d-block d-md-none mb-4"><a id="products-bt-now-mobile" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>

                <h2 className="text-dark text-size-24 text-spacing-1 font-weight-normal">{t(`Stream torrents while you download.`)}</h2>
                <h2 className="text-dark text-size-24 text-spacing-1 font-weight-normal mb-4">{t(`Ideal for new users.`)}</h2>
                {
                  !this.state.isMac &&
                    <Button onClick={this.handleWebClick} id="win-homepage-hero-btweb-comp" href="/products/win/bittorrent-web-compare/" className=" btn-primary text-spacing-1 mt-0 mt-md-3 mb-3 button-filled text-spacing-1">{t(`Free Download`)}</Button>
                }
                <Button onClick={this.handleWebClick} id="mac-homepage-hero-btweb-comp" href="/products/mac/bittorrent-web-compare/" className= {`${this.state.displayMac} btn-primary text-spacing-1 mt-0 mt-md-3 mb-2 button-filled text-spacing-1`}>{t(`Free Download`)}</Button>
              </div>
              <div className="col-md-6 mb-5 mb-md-0 order-md-2 d-flex justify-content-center flex-column">
                {/*<img src={btWebImg} alt="Download BitTorrent Web" className="img-fluid d-none d-md-block hero-img"></img>*/}

                <picture>
                  <source srcSet={`${btWebImgWebp} 1x, ${btWebImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${btWebImg} 1x, ${btWebImg2x} 2x`} src={btWebImg} className="img-fluid w-100 d-none d-md-block hero-img" alt="Download BitTorrent Web"></img>
                </picture>
                <div style={{maxWidth: '420px', fontSize: '10px'}} className="d-none d-md-block"><a id="products-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
              </div>
            </div>
          </div>
        </div>

        <div className="top-section-container py-5 position-relative overflow-hidden " >
          
            <div className="container">
              <div className="row text-center ">
                <div className="col-md-6 mt-md-4 order-md-2">
                  <Logo color="black" tag="h2" productName="Classic" className="mb-4"/>
                  {/*<img src={classicLaptop} alt="Download BitTorrent Classic" className="img-fluid d-block d-md-none mb-4"></img>*/}

                  <picture>
                    <source srcSet={`${classicLaptopWebp} 1x, ${classicLaptopWebp2x} 2x`} type="image/webp"></source>
                    <img srcSet={`${classicLaptop} 1x, ${classicLaptop2x} 2x`} src={classicLaptop} className="img-fluid w-100 d-block d-md-none mb-4" alt="Download BitTorrent Classic"></img>                  
                  </picture>

                  <h1 className="text-size-24 text-spacing-1 font-weight-normal">{t(`Get the original desktop torrent app`)}</h1>
                  <h2 className="text-size-24 text-spacing-1 font-weight-normal mb-4">{t(`for experienced users.`)}</h2>
                  {
                    !this.state.isMac &&
                    <Button 
                      id="win-homepage-hero-classic-comp"
                      href="/products/win/bittorrent-classic-compare/" 
                      onClick={this.handleClassicClick} 
                      className="btn-primary text-spacing-1 mt-0 mt-md-3 mb-3 button-filled text-spacing-1">
                        {t(`Free Download`)}
                    </Button>
                  }
                    <Button 
                      id="mac-homepage-hero-classic-dl"
                      href="/downloads/complete/classic/" 
                      onClick={this.handleClassicClick} 
                      className= {`${this.state.displayMac} btn-primary text-spacing-1 mt-0 mt-md-3 mb-3 button-filled text-spacing-1`}>
                        {t(`Free Download`)}
                    </Button>

                    <div className={this.state.displayMac}>
                      <img src={warning} alt="Warning Icon" style={{ height: `20px` }} className="warning-icon mr-1 d-inline pb-1"/>
                      <p className="d-inline text-left text-size-14">{t(`Not compatible with MAC OS <br/> Catalina Version 10.15 and above`)}</p>
                    </div>
                </div>
                <div className="col-md-6 mb-5 order-md-1">
                  {/*<img src={classicLaptop} alt="Download BitTorrent Classic" className="img-fluid d-none d-md-block"></img>*/}

                <picture>
                  <source srcSet={`${classicLaptopWebp} 1x, ${classicLaptopWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${classicLaptop} 1x, ${classicLaptop2x} 2x`} src={classicLaptop} className="img-fluid w-100 d-none d-md-block" alt="Download BitTorrent Classic"></img>                  
                </picture>

                </div>
              </div>
            </div>
            <div className="container z-2 position-relative">
              <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`FeaturesHeader`)}</p>
              <p className="text-center mb-5 text-size-24">{t(`BitTorrent Classic Torrent`)} {t(`Desktop App Features`)}.</p>
              <div className = "index-features-list m-auto ">
                <FeaturesList text={text} isMac={this.state.isMac}/>
              </div>
            </div>


        </div>
          {
            !this.state.isMac &&
              <div className="py-5 position-relative overflow-hidden" id="panels">
                <div className="z-2 pt-5">
                  <div className="position-absolute h-50 w-100" style={backgrounds.greyGradientBg} />
                  <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`Premium`)}</p>
                  <p className="text-center mb-5 text-size-24">{t(`Check out our Premium Safe Torrenting Software for Windows`)}.</p>

                  <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac":this.state.isMac }} >
                    <p className="w-100 text-center mt-5">{t(`Learn more about our premium products:`)}  <span className='d-block d-sm-inline-block'><LocalizedLink id="win-homepage-btweb-premium" to="/products/win/bittorrent-web-premium/">BitTorrent Web</LocalizedLink> | <LocalizedLink id="win-homepage-classic-premium" to="/products/win/bittorrent-classic-premium/">BitTorrent Classic</LocalizedLink></span> </p>
                    <p className="w-100 text-center mb-5 pb-4">{t(`*Available in BitTorrent Classic only.`)}</p>
                  </ComparisonPanels>
                </div>
              </div>
          }

        <div class="container-fluid px-0" style={backgrounds.greyGradientBg}>
          <div className="container py-5 pt-0">
            <p className="text-center font-weight-bold feature-list-title-2 pt-0 mb-3">{t(`All Products`)}</p>
            <ProductCatalog pageContext={{text:text}}/>
          </div>
        </div>
        {
          englishHomepage.startsWith('/products') &&
        <div className="container-fluid px-0 py-5">
          <div className="container">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3 pt-md-5">{t(`Languages`)}</p>
            <h3 className="text-center mb-5 text-size-24 font-weight-normal">{t(`BitTorrent is global`)}.</h3>
            <p className="text-center mb-5 text-size-16 font-weight-normal">{t(`Our website is fully translated in 17 languages. Select one of our popular international sites below or use <span class='d-lg-block'>the language tab at the top of the page to visit the international site of your choice.</span>`)}.</p>
            <div className="d-block d-md-flex justify-content-md-around">
              <a id="homepage-languages-brazil-flag" className="d-flex flex-column align-items-center text-decoration-none" href="/pt-br/">
                <img className="img-fluid shadow-lg mb-2" src={flagBrazil} alt="BitTorrent for Brazil - Torrent Baixar"></img>
                <p className="text-size-16 font-weight-bold text-dark">Torrent Baixar</p>
              </a>
              <a id="homepage-languages-korea-flag" className="d-flex flex-column align-items-center text-decoration-none" href="/ko/">
                <img className="img-fluid shadow-lg mb-2" src={flagKorea} alt="BitTorrent for Korea - 토런트 다운로드"></img>
                <p className="text-size-16 font-weight-bold text-dark">토런트 다운로드</p>
              </a>
              <a id="homepage-languages-spain-flag" className="d-flex flex-column align-items-center text-decoration-none" href="/es/">
                <img className="img-fluid shadow-lg mb-2" src={flagSpain} alt="BitTorrent for Spain - Descargar Torrent"></img>
                <p className="text-size-16 font-weight-bold text-dark">Descargar Torrent</p>
              </a>
              <a id="homepage-languages-russia-flag" className="d-flex flex-column align-items-center text-decoration-none" href="/ru/">
                <img className="img-fluid shadow-lg mb-2" src={flagRussia} alt="BitTorrent for Russia - качать торрент"></img>
                <p className="text-size-16 font-weight-bold text-dark">скачать торрент</p>
              </a>
            </div>
            <div className="mb-5">&nbsp;</div>
          </div>
        </div>
      }

      </>
    )

  }
}


Index.contextType = CompatibilityContext;

export default Index


