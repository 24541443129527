import React from 'react';
import useTranslations from "../utils/useTranslations"
import LocalizedLink from "./localizedLink"
import arrowBlack from "../images/icons/arrow-black.svg"

import logoRemote from '../images/components/productCatalog/logo-remote.png'
import logoAndroid from '../images/components/productCatalog/logo-android.png'
import logoWeb from '../images/components/productCatalog/logo-web.png'
import logoClassic from '../images/components/productCatalog/logo-classic.png'
import logoBtfs from '../images/components/productCatalog/logo-btfs.svg'
import logoSpeed from '../images/components/productCatalog/logo-speed.png'

class ProductCatalog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedFilter : '*',
			isMobile: false,
		}
	}

	componentDidMount() {
		this.setState({
			isMobile:window.gatsbyIsMobile,
		})
		this.filter('*')
	}

	filter = (filterType) => {
		if (typeof window !== `undefined`) {

			if (this.iso === undefined){
				const Isotope = require("isotope-layout/js/isotope");

				this.iso = new Isotope( '.grid', {
					itemSelector: '.grid-item',
					layoutMode: 'fitRows',
				});

				this.iso.arrange({
					filter: '*'
				})
			}

			this.setState({selectedFilter: filterType})
			this.iso.arrange({
				filter: filterType
			})
		}
	}


	render() {
		const { text, pageName } = this.props.pageContext
		const t = useTranslations(text)

		return(<>

			<div className={`${this.state.isMobile ? 'd-none': 'd-none d-lg-flex'} justify-content-center product-catalog-nav flex-column flex-lg-row`}>
				<div className={`${this.state.selectedFilter === "*" ? 'selected' : ''} product-catalog-nav-item d-inline-block text-center py-1 py-lg-0 mx-3`} onClick={() => {this.filter('*')}}>
					{t(`Show All`)}
				</div>
				<div className={`${this.state.selectedFilter === ".torrent-downloaders" ? 'selected' : ''} product-catalog-nav-item d-inline-block text-center py-1 py-lg-0 mx-3`} onClick={() => {this.filter('.torrent-downloaders')}}>
					{t(`Torrent Downloaders`)}
				</div>
				<div className={`${this.state.selectedFilter === ".content-utility" ? 'selected' : ''} product-catalog-nav-item d-inline-block text-center py-1 py-lg-0 mx-3`} onClick={() => {this.filter('.content-utility')}}>
					{t(`Content Utility`)}
				</div>
				<div className={`${this.state.selectedFilter === ".decentralized-storage" ? 'selected' : ''} product-catalog-nav-item d-inline-block text-center py-1 py-lg-0 mx-3`} onClick={() => {this.filter('.decentralized-storage')}}>
					{t(`Decentralized Storage`)}
				</div>
			</div>

			<div className="grid product-catalog position-relative overflow-hidden py-5" >
				<div className="grid-item product-catalog-item torrent-downloaders">
					<div className="product-catalog-content justify-content-center d-flex align-items-center flex-column py-4 px-4">
						<div className="product-catalog-logo d-flex justify-content-center">
							<img src={logoSpeed} className="mb-4"/>
						</div>
						<div className="product-catalog-description text-center">
							{t(`Get faster downloads and rewards.`)}
						</div>
					</div>
				</div>
				<div className="grid-item product-catalog-item decentralized-storage">
					<div className="product-catalog-content justify-content-center d-flex align-items-center flex-column py-4 px-4">
						<div className="product-catalog-logo d-flex justify-content-center">
							<img src={logoBtfs} className="mb-4" style={{height:'66px'}}/>
						</div>
						<div className="product-catalog-description text-center">
							{t(`The First Scalable Decentralized Storage System.`)}
						</div>
						<div className="mt-4 mb-2">
							<LocalizedLink 
			                  id="products-catalog-token-learnmore"
			                  className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
			                  to="/token/bittorrent-file-system/">

			                 {t(`Learn more`)}

			                </LocalizedLink>
							<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
						</div>
					</div>
				</div>


				<div className="grid-item product-catalog-item torrent-downloaders">
					<div className="product-catalog-content justify-content-center d-flex align-items-center flex-column py-4 px-4">
						<div className="product-catalog-logo d-flex justify-content-center align-items-start">
							<img src={logoClassic} className="mb-4" />
						</div>
						<div className="product-catalog-description text-center">
							{t(`Get the original desktop torrent app`)} {t(`for experienced users.`)}

						</div>
						<div className="mt-4 mb-2">
							<LocalizedLink 
			                  id="products-catalog-token-learnmore"
			                  className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
			                  to="/products/win/bittorrent-classic-free/">

			                 {t(`Learn more`)}

			                </LocalizedLink>
							<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
						</div>
					</div>
				</div>

				<div className="grid-item product-catalog-item torrent-downloaders">
					<div className="product-catalog-content justify-content-center d-flex align-items-center flex-column py-4 px-4">
						<div className="product-catalog-logo d-flex justify-content-center">
							<img src={logoWeb} className="mb-4" style={{maxHeight: '55px'}}/>
						</div>
						<div className="product-catalog-description text-center">
							{t(`Stream torrents while you download.`)} {t(`Ideal for new users.`)}
						</div>
						<div className="mt-4 mb-2">
							<LocalizedLink 
			                  id="products-catalog-token-learnmore"
			                  className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
			                  to="/products/win/bittorrent-web-free/">

			                 {t(`Learn more`)}

			                </LocalizedLink>
							<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
						</div>
					</div>
				</div>

				<div className="grid-item product-catalog-item torrent-downloaders">
					<div className="product-catalog-content justify-content-center d-flex align-items-center flex-column py-4 px-4">
						<div className="product-catalog-logo d-flex justify-content-center">
							<img src={logoAndroid} className="mb-4"/>
						</div>
						<div className="product-catalog-description text-center">
							{t(`The most trusted mobile torrent downloader.`)}
						</div>
						<div className="mt-4 mb-2">
							<LocalizedLink 
			                  id="products-catalog-token-learnmore"
			                  className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
			                  to="/products/android/bittorrent-android-free/">

			                 {t(`Learn more`)}

			                </LocalizedLink>
							<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
						</div>
					</div>
				</div>

				<div className="grid-item product-catalog-item content-utility">
					<div className="product-catalog-content justify-content-center d-flex align-items-center flex-column py-4 px-4">
						<div className="product-catalog-logo d-flex justify-content-center">
							<img src={logoRemote} className="mb-4"/>
						</div>
						<div className="product-catalog-description text-center">
							{t(`Anywhere access to your home BitTorrent Client.`)}
						</div>
						<div className="mt-4 mb-2">
							<a
			                  id="products-catalog-token-learnmore"
			                  className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
			                  href="https://remote.bittorrent.com/"
			                  referrer="noreferrer noopener"
			                  target="__blank">

			                 {t(`Learn more`)}

			                </a>
							<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
						</div>
					</div>
				</div>

			</div>


		</>)
	}
}

export default ProductCatalog

